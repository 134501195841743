import React from "react";
import InstagramFeed from "./InstagramFeed";
import "./Footer.css";

export default () => (
  <div>
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} gftee, inc. All rights
          reserved.
        </span>
      </div>
    </footer>
  </div>
);
